import React, { useState, useEffect } from 'react';
// eslint-disable-next-line
import { IconButton, Box, Typography, Select, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Close as CloseIcon } from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import menuIcon from '../img/menu.svg'; 

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'en'; // Vérifier la langue enregistrée ou utiliser 'en' par défaut
    i18n.changeLanguage(savedLanguage);
}, [i18n]);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  // eslint-disable-next-line
  const handleChangeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem('language', selectedLanguage); // Sauvegarder la langue
};
  return (
    <Box component="header" sx={{ position: 'relative', width: '100%' }}>
      {/* Section du header */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          top: 0,
          width: '100%',
          position: 'fixed',
          zIndex: '10',
          py: 1,
          backgroundColor: '#ffffff',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Ranade, sans-serif',
            fontWeight: 300,
            letterSpacing: '0.02em',
            color: '#000000',
            ml: 2,
            cursor: 'pointer',
          }}
          onClick={() => navigate('/')} // Redirection vers la page d'accueil
        >
          KAJIKA AKI
        </Typography>

        <IconButton
          aria-label="menu"
          onClick={handleMenuToggle}
          sx={{ color: '#000000', mr: 1, height: '40px', width: '40px' }}
        >
          {menuOpen ? <CloseIcon sx={{ fontSize: '22px' }} /> : <img src={menuIcon} alt="Menu" style={{ height: '26px' }} />}
        </IconButton>
      </Box>

      {/* Menu en plein écran */}
      <AnimatePresence>
        {menuOpen && (
          <motion.div
            key="menu"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              backgroundColor: '#ffffff',
              zIndex: 9,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >

            <Typography
              variant="h5"
              sx={{
                mb: 2,
                fontFamily: 'Manrope',
                fontWeight: '400',
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate('/about');
                setMenuOpen(false);
              }}
            >
              {t('about')}
            </Typography>
            
            <Typography
              variant="h5"
              sx={{
                mb: 2,
                fontFamily: 'Manrope',
                fontWeight: '400',
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate('/flowers');
                setMenuOpen(false);
              }}
            >
              {t('Flowers of Joy')}
            </Typography>

            <Typography
              variant="h5"
              sx={{
                mb: 2,
                fontFamily: 'Manrope',
                fontWeight: '400',
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate('/frescoes');
                setMenuOpen(false);
              }}
            >
              {t('fresques')}
            </Typography>

            <Typography
              variant="h5"
              sx={{
                cursor: 'pointer',
                fontFamily: 'Manrope',
                fontWeight: '400',
                mb: 4,
              }}
              onClick={() => {
                window.location.href = 'mailto:hey@kajikaaki.com';
                setMenuOpen(false);
              }}
            >
              {t('contact')}
            </Typography>

            {/* Sélecteur de langue 
            <Select
              value={i18n.language}
              onChange={handleChangeLanguage}
              sx={{ minWidth: 100 }}
            >
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="fr">Français</MenuItem>
            </Select> */}

          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
}
