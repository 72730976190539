import React from 'react';
import { Box } from '@mui/material';
import Header from '../components/Header';
import ArtworkSlider from '../components/ArtworkSlider';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

const Index = () => {

  return (
    <Box>
      <Helmet>
        <title>KAJIKA AKI</title>
      </Helmet>
        <Box>
          <Header />
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <ArtworkSlider />
          </motion.div>
        </Box>
    </Box>
  );
};

export default Index;
