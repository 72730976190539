import React, { useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Lenis from '@studio-freight/lenis';
import Header from '../components/Header';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { motion } from 'framer-motion';

gsap.registerPlugin(ScrollTrigger);

const Flowers = () => {
  // eslint-disable-next-line
  const { t } = useTranslation();
  const introRef = useRef(null);
  const textRef = useRef(null);
  const arrowRef = useRef(null);
  const lenisRef = useRef(null);

  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
      smoothWheel: true,
    });
    lenisRef.current = lenis;

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);

    gsap.fromTo(
      arrowRef.current,
      { y: -10 },
      { y: 10, repeat: -1, yoyo: true, ease: 'power1.inOut', duration: 2 }
    );

    ScrollTrigger.create({
      trigger: textRef.current,
      start: 'top bottom',
      onEnter: () => {
        gsap.to(arrowRef.current, { opacity: 0, duration: 0.5 });
      },
      onLeaveBack: () => {
        gsap.to(arrowRef.current, { opacity: 1, duration: 0.5 });
      },
    });
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Box>
        <Header />
        <Box
          ref={introRef}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            textAlign: 'center',
            backgroundColor: 'white',
            position: 'relative',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              px: { xs: 3, md: 10 },
              maxWidth: '600px',
              fontFamily: 'Manrope, sans-serif',
              mb: 0.5,
              lineHeight: '1.4em',
            }}
          >
              The Flower of Joy is made up of 5 branches and 5 petals, a star-shaped heart and 5 small dots.
          </Typography>
          <Box
            ref={arrowRef}
            sx={{
              position: 'absolute',
              bottom: '20px',
              left: '50%',
              transform: 'translateX(-50%)',
              fontSize: '1.5rem',
              cursor: 'pointer',
            }}
          >
            <KeyboardArrowDownIcon fontSize="medium" />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb:10 }}>
          <Box
            ref={textRef}
            sx={{
              backgroundColor: 'white',
              lineHeight: '2',
              px: { xs: 3, md: 10 },
              maxWidth: '800px',
            }}
          >
            <Typography variant="h5" sx={{ fontFamily: 'Manrope, sans-serif', lineHeight: '1.8em', mb: 4 }}>
            Flowers of Joy is the joy in ourselves that permits everyone’s joy.
            </Typography>
            <Typography variant="h5" sx={{ fontFamily: 'Manrope, sans-serif', lineHeight: '1.8em', mb: 4 }}>
            The Flower of Joy has a body with 5 branches: it has a head, two arms and two legs, stretched out and relaxed. It has a star-shaped heart where the colors of its intimacy reside, and 5 little dancing dots. Its body is dressed in 5 petals that envelop its body in colors. 
            </Typography>
            <Typography variant="h5" sx={{ fontFamily: 'Manrope, sans-serif', lineHeight: '1.8em', mb: 4 }}>
            The Flower of Joy represents the constant in the midst of chaos, the diamond of life hidden within each individual, the possibility of fulfillment for every living individual; animal, vegetable, atomic or human. 
            </Typography>
            <Typography variant="h5" sx={{ fontFamily: 'Manrope, sans-serif', lineHeight: '1.8em', mb: 4 }}>
            The Flower of Joy is a space of absence of contradiction, where all colors combinations are possible and where the boundary between self and other disappears. 
            </Typography>
            <Typography variant="h5" sx={{ fontFamily: 'Manrope, sans-serif', lineHeight: '1.8em', mb: 4 }}>
            The Flower of Joy is the most intimate and precise work in Kajika Aki's world. Through this quiet and direct work of art, Kajika's intention is to open up a space of intimacy for all, a space of freedom and a new, optimistic vision of the world.  
            </Typography>
            <Typography variant="h5" sx={{ fontFamily: 'Manrope, sans-serif', lineHeight: '1.8em', mb: 4 }}>
            Each Flowers of Joy's piece bears the title of the period in which it was created.  
            </Typography>
            <Typography variant="h5" sx={{ fontFamily: 'Manrope, sans-serif', lineHeight: '1.8em', mb: 4 }}>
            The Flower of Joy's pieces are created mainly on paper with paints created by Kajika Aki; she carefully selects the binder and pigments, paying particular attention to the intensity and precision of the colors chosen.
            </Typography>
          </Box>
        </Box>
      </Box>
    </motion.div>
  );
};

export default Flowers;
