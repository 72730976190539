import React, { useEffect, useRef } from 'react';
import { Container, Typography, Box, Grid, Link } from '@mui/material';
import Header from '../components/Header';
import { motion } from 'framer-motion';
import Lenis from '@studio-freight/lenis';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

const About = () => {
  const lenisRef = useRef(null);
  const { t } = useTranslation();

  // Gestion du scrolling smooth avec Lenis
  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
    });
    lenisRef.current = lenis;

    function raf(time) {
      if (lenis) {
        lenis.raf(time);
        requestAnimationFrame(raf);
      }
    }
    requestAnimationFrame(raf);

    return () => {
      if (lenisRef.current) {
        lenisRef.current.destroy();
      }
    };
  }, []);

  // Données statiques extraites de about.json maintenant intégrées avec i18n
  const data = {
    biography: t('about_page.biography'),
    exhibitions: [
      {
        year: "2024",
        title: t('about_page.exhibitions.0.title'),
        organized_by: t('about_page.exhibitions.0.organized_by'),
        city: t('about_page.exhibitions.0.city'),
        venue: t('about_page.exhibitions.0.venue'),
        dates: "dec 2024",
        type: "SOLO"
      },
      {
        year: "2022",
        title: t('about_page.exhibitions.1.title'),
        organized_by: t('about_page.exhibitions.1.organized_by'),
        city: t('about_page.exhibitions.1.city'),
        venue: t('about_page.exhibitions.1.venue'),
        dates: "avril 2022",
        type: "SOLO"
      }
    ],
    awards: [
      {
        title: t('about_page.awards.0.title'),
        event: t('about_page.awards.0.event'),
        year: 2021
      },
      {
        title: t('about_page.awards.1.title'),
        event: t('about_page.awards.1.event'),
        year: 2021
      },
      {
        title: t('about_page.awards.2.title'),
        event: t('about_page.awards.2.event'),
        year: 2022
      }
    ],
    articles: [
      {
        title: "Berlin has resilience in its DNA",
        publication: "Monopol",
        year: 2024,
        link: "https://www.monopol-magazin.de/interview-dimitri-hegemann-megafence-tresor-berlin"
      },
      {
        title: "A dialogue between art, community and urban renewal",
        publication: "Art Rabbit",
        year: 2024,
        link: "https://www.artrabbit.com/events/kajika-aki-claim-for-peace"
      },
      {
        title: "Parisian artist transforms the Tresor billboard into a work of art",
        publication: "Tagesspiegel",
        year: 2024,
        link: "https://www.tagesspiegel.de/kultur/pariser-kunstlerin-verwandelt-die-tresor-plakatwand-in-ein-kunstwerk-neunzig-meter-fur-den-frieden-12882685.html"
      },
      {
        title: "A Canvas for the City: Tresor Foundation and Kajika Aki",
        publication: "Bpigs",
        year: 2024,
        link: "https://bpigs.com/openings/a-canvas-for-the-city-tresor-foundation-presents-public-art-installation-by-kajika-aki"
      },
    ]
  };

  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const blockVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <>
      <Helmet>
        <title>KAJIKA AKI - {t('about')}</title>
      </Helmet>
      <Header />
      <Container sx={{ pt: { xs: 7, md: 10 }, pb: 10, px: { xs: 5, md: 15 } }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
              variants={fadeInVariants}
            >
              <Box>
                <img
                  src="/assets/images/portrait.jpg"
                  alt={t('about_page.biography')}
                  style={{ width: '100%', borderRadius: '4px' }}
                />
              </Box>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={8}>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
              variants={fadeInVariants}
            >
              <Box sx={{ lineHeight: '1.8' }}>

                <Typography fontWeight="bold">Born in 1993 in Paris</Typography>
                <Typography fontWeight="bold" sx={{ mb:2 }}>Lives and works in Brussels</Typography>
                
                <Typography sx={{ mb:1 }}>Kajika Aki is a Franco-Japanese painter, animated film director and comic book author. </Typography>

                <Typography sx={{ mb:1 }}>Her artistic work is inspired by all the arts, from the paintings of Georgia O'Keeffe to the drawings of Katsuhiro Ôtomo and the choreography of Pina Bausch. Her world is both sensitive and sensory, depicting the richness of human emotions with great freedom. </Typography>

                <Typography sx={{ mb:1 }}>Following her degree in illustration, and a year and a half studying 2D Animation, in 2017 she left the Gobelins school curriculum to make her first animated film <i>MOM</i>. Produced solo over a period of one year, <i>MOM</i> received critical acclaim and numerous festival awards. </Typography>

                <Typography sx={{ mb:1 }}>In 2019 and 2020, in her 16m2 flat in Paris, she created <i>The Life and the Death of the Little Mermaid</i> and <i>IMIEAMORI</i>, two gigantic works of art measuring 1.5m by 10m in length, made entirely on paper, without preliminary sketches, using Indian ink and watercolor. </Typography>

                <Typography> Having no contact with the contemporary art world, it was in 2021 that she was discovered by Lionel Bensemoun, who decided to exhibit her two gigantic works for the first time at La Folie Barbizon from March 16, 2022, to May 16, 2022.</Typography>

              </Box>
            </motion.div>
          </Grid>
        </Grid>

        <Box mt={4}>
          <Typography variant="h6" component="h2" gutterBottom>
            {t('about_page.extitle')}
          </Typography>
          {data.exhibitions.map((exhibition, index) => (
            <motion.div
              key={index}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
              variants={blockVariants}
            >
              <Grid
                container
                spacing={0}
                alignItems="center"
                sx={{
                  borderTop: '1px solid #ccc',
                  borderBottom: index === data.exhibitions.length - 1 ? '1px solid #ccc' : 'none',
                  paddingY: 2,
                }}
              >
                <Grid item xs={2}>
                  <Typography textTransform={'uppercase'} variant="body2">
                    {exhibition.dates}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {exhibition.title}
                  </Typography>
                  <Typography variant="body2">
                    {exhibition.organized_by} {exhibition.city} {exhibition.venue}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" align="right">
                    {exhibition.type}
                  </Typography>
                </Grid>
              </Grid>
            </motion.div>
          ))}
        </Box>


        <Box sx={{ mt: 6 }}>
          <Typography variant="h6" component="h2" gutterBottom>
          {t('about_page.presstitle')}
          </Typography>
          {data.articles.map((article, index) => (
            <motion.div
              key={index}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
              variants={blockVariants}
            >
              <Grid
                container
                spacing={0}
                alignItems="center"
                sx={{
                  borderTop: '1px solid #ccc',
                  borderBottom: index === data.articles.length - 1 ? '1px solid #ccc' : 'none',
                  paddingY: 2,
                }}
              >
                <Grid item xs={8}>
                  <Typography variant="body1" >
                    <Link href={article.link} target="_blank" rel="noopener noreferrer" color="black" underline="none" sx={{ display:'flex', alignItems:'center', gap:'5px' }} >
                      <strong>{article.title}</strong> <ArrowOutwardIcon fontSize="20px" />
                    </Link>
                  </Typography>
                  <Typography variant="body2">
                    {article.publication}
                  </Typography>
                </Grid>
                <Grid item xs={4} textAlign="right">
                  <Typography textTransform={'uppercase'} variant="body2">
                    {article.year}
                  </Typography>
                </Grid>
              </Grid>
            </motion.div>
          ))}
        </Box>

        <Box sx={{ mt: 6 }}>
          <Typography variant="h6" component="h2" gutterBottom>
            {t('about_page.awtitle')}
          </Typography>
          {data.awards.map((award, index) => (
            <motion.div
              key={index}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
              variants={blockVariants}
            >
              <Grid
                container
                spacing={0}
                alignItems="center"
                sx={{
                  borderTop: '1px solid #ccc',
                  borderBottom: index === data.awards.length - 1 ? '1px solid #ccc' : 'none',
                  paddingY: 2,
                }}
              >
                <Grid item xs={2}>
                  <Typography textTransform={'uppercase'} variant="body2">
                    {award.year}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <strong>{award.event}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={4} textAlign="right">
                  <Typography textTransform={'uppercase'} variant="body2">
                    {award.title}
                  </Typography>
                </Grid>
              </Grid>
            </motion.div>
          ))}
        </Box>

      </Container>
    </>
  );
};

export default About;
