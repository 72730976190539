import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, IconButton, Drawer, Typography } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import CloseIcon from '@mui/icons-material/Close';
import ZoomArtwork from '../components/ZoomArtwork';
import NotFound from '../components/NotFound';
import menuIcon from '../img/flowers.svg';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const Artwork = () => {
  const { id } = useParams(); 
  const [artwork, setArtwork] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate(); 
  const { t } = useTranslation();

  useEffect(() => {
    const fetchArtwork = async () => {
      try {
        const response = await fetch('/data/flowers.json');
        const data = await response.json();
        const foundArtwork = data.find((item) => item.id === parseInt(id));
        setArtwork(foundArtwork);
        setLoading(false);
      } catch (error) {
        console.error('Erreur lors de la récupération de l\'œuvre', error);
        setLoading(false);
      }
    };

    fetchArtwork();
  }, [id]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Box
          component="img"
          src={menuIcon}
          alt="Loading"
          sx={{
            width: '45px',
            height: '45px',
            animation: 'spin 6s linear infinite',
          }}
        />
        <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
      </Box>
    );
  }

  if (!artwork) {
    return <Box> <NotFound /> </Box>;
  }

  return (
    <Box>
      <Helmet>
        <title>{artwork.title || 'KAJIKA AKI'}</title>
      </Helmet>
      <IconButton
        onClick={() => navigate('/')}
        sx={{
          position: 'fixed',
          top: 10,
          left: 10,
          zIndex: 10,
          color: '#000000',
          borderRadius: '50%',
          backgroundColor: '#FFF',
          width: '48px',
          height: '48px',
          '&:hover': {
            backgroundColor: '#ffffff',
          },
        }}
      >
        <ArrowBackOutlinedIcon sx={{ fontSize: '22px' }} />
      </IconButton>

      <IconButton
        onClick={toggleDrawer}
        sx={{
          position: 'fixed',
          top: 10,
          right: 10,
          zIndex: 10,
          color: '#000000',
          borderRadius: '50%',
          backgroundColor: '#FFF',
          width: '48px',
          height: '48px',
          '&:hover': {
            backgroundColor: '#ffffff',
          },
        }}
      >
        <BookmarkIcon sx={{ fontSize: '22px' }} />
      </IconButton>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        PaperProps={{
          sx: {
            width: '400px',
            backgroundColor: '#fff',
            padding: 2,
          },
        }}
        transitionDuration={300}
      >
        <Box sx={{ position: 'relative', height: '100%' }}>
          <IconButton
            onClick={toggleDrawer}
            sx={{
              position: 'absolute',
              top: 10,
              left: 20,
              color: 'black',
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ padding: 4, marginTop: 5 }}>
            <Typography variant="h6" gutterBottom>
              {t(`artwork_titles.${artwork.slug}`, { defaultValue: artwork.title })}
            </Typography>
            <Typography variant="body1" paragraph>
              {t(`artwork_sizes.${artwork.slug}`, { defaultValue: artwork.size })}
            </Typography>
          </Box>
        </Box>
      </Drawer>

      <ZoomArtwork imageSrc={`/assets/images/${artwork.heavyImage}`} />
    </Box>
  );
};

export default Artwork;