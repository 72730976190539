import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Drawer, Typography } from '@mui/material';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import CloseIcon from '@mui/icons-material/Close';
import { motion } from 'framer-motion';
import menuIcon from '../img/flowers.svg';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Helmet } from 'react-helmet';

const Littlemermaid = () => {
  const [imageLoaded, setImageLoaded] = useState(false);  // État pour suivre si l'image est chargée
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Helmet>
        <title>KAJIKA AKI - Frescoes</title>
      </Helmet>
      <Box display="flex" justifyContent="center" alignItems="center" style={{ height:'100vh', width: '100vw', overflow: 'hidden', cursor:'move' }}>
        {!imageLoaded && (
          <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <Box
            component="img"
            src={menuIcon}
            alt="Loading"
            sx={{
              width: '45px',
              height: '45px',
              animation: 'spin 6s linear infinite',
            }}
          />
          <style>{`
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `}</style>
        </Box>
        )}
        <TransformWrapper
          defaultScale={1}
          defaultPositionX={0}
          defaultPositionY={0}
          wheel={{
            step: 0.5,
          }}
        >
          {({ zoomIn, zoomOut }) => (
            <>
              <TransformComponent>
                <img 
                  src="/assets/frescoes/bw-large.jpg" 
                  alt="Large Colorful Monster" 
                  style={{ height: '100vh', display: imageLoaded ? 'block' : 'none' }} 
                  onLoad={() => setImageLoaded(true)}  // Gestionnaire onLoad pour mettre à jour l'état lorsque l'image est chargée
                />
              </TransformComponent>

              {imageLoaded && (
                <>
                  {/* Ajout du IconButton pour le retour */}
                  <IconButton
                    onClick={() => navigate('/frescoes')}
                    sx={{
                      position: 'fixed',
                      top: 10,
                      left: 10,
                      zIndex: 10,
                      color: '#000000',
                      borderRadius: '50%',
                      backgroundColor: '#FFF',
                      width: '48px',
                      height: '48px',
                      '&:hover': {
                        backgroundColor: '#ffffff',
                      },
                    }}
                  >
                    <ArrowBackOutlinedIcon sx={{ fontSize: '22px' }} />
                  </IconButton>

                  {/* Bouton d'informations */}
                  <IconButton
                    onClick={toggleDrawer}
                    sx={{
                      position: 'fixed',
                      top: 10,
                      right: 10,
                      zIndex: 10,
                      color: '#000000',
                      borderRadius: '50%',
                      backgroundColor: '#FFF',
                      width: '48px',
                      height: '48px',
                      '&:hover': {
                        backgroundColor: '#ffffff',
                      },
                    }}
                  >
                    <BookmarkIcon sx={{ fontSize: '22px' }} />
                  </IconButton>

                  {/* Drawer */}
                  <Drawer
                    anchor="right"
                    open={isDrawerOpen}
                    onClose={toggleDrawer}
                    PaperProps={{
                      sx: {
                        width: '400px', // Drawer moins large
                        backgroundColor: '#fff',
                        padding: 2,
                      },
                    }}
                    transitionDuration={300} // Transition plus douce
                  >
                    <Box sx={{ position: 'relative', height: '100%' }}>
                      <IconButton
                        onClick={toggleDrawer}
                        sx={{
                          position: 'absolute',
                          top: 10,
                          left: 20,
                          color: 'black',
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <Box sx={{ padding: 4, marginTop: 5 }}>
                        <Typography variant="h6" gutterBottom>
                          The Life and Death of the Little Mermaid
                        </Typography>
                        <Typography variant="body1">
                          The Life and Death of the Little Mermaid is a piece from 2019, created in one continuous flow on a 1.5 x 10 meter sheet of paper, all in ink and without any preliminary sketches. Kajika's art speaks of inner monsters but also of animal and human-like creatures that help reveal and heal from trauma.
                        </Typography>
                      </Box>
                    </Box>
                  </Drawer>

                  {/* Boutons de zoom */}
                  <Box position="absolute" bottom="15px" left="50%" zIndex="tooltip" sx={{ transform: 'translateX(-50%)', display: 'flex', gap: 1 }}>
                    <IconButton 
                    onClick={() => zoomOut()}
                      sx={{ 
                        backgroundColor: 'white', 
                        boxShadow: 'none', 
                        height: '38px', 
                        width: '38px',
                        color: 'black', // Icône noire
                        '&:hover': {
                          backgroundColor: 'white', // Assurer que le fond reste blanc au hover
                          boxShadow: 'none' // Aucune ombre au hover
                        }
                      }} 
                      aria-label="zoom-out"
                    >
                      <RemoveIcon fontSize="20px" />
                    </IconButton>
                    <IconButton 
                    onClick={() => zoomIn()}
                      sx={{ 
                        backgroundColor: 'white', 
                        boxShadow: 'none', 
                        height: '38px', 
                        width: '38px',
                        color: 'black', // Icône noire
                        '&:hover': {
                          backgroundColor: 'white', // Assurer que le fond reste blanc au hover
                          boxShadow: 'none' // Aucune ombre au hover
                        }
                      }} 
                      aria-label="zoom-in"
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>


                </>
              )}
            </>
          )}
        </TransformWrapper>
      </Box>
      </motion.div>
  );
};

export default Littlemermaid;
